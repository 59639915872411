import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'
import { baseURL } from '../../config';
import DialogTitle from '@mui/material/DialogTitle';
import Headerone from '../headerone/newMain'
import About from '../muiHome/About'
import Courses from '../muiHome/Courses'
import Event from '../muiHome/Event'
import PressRelease from '../muiHome/PressRelease';
import Scholarship from '../muiHome/Scholarship'
import Homegallery from '../muiHome/Homegallery'
import Shep from '../muiHome/Shep'
import Slider from '../muiHome/Sliderone'
import Frequently from '../muiHome/Frequently'
import Video from '../muiHome/Video'
import Testimonial from '../muiHome/Testimonial';
import Search from '../muiHome/Search'
import Admission from '../muiHome/Admission'
import Redslider from '../muiHome/Redslider'
import Blog from '../muiHome/Blog'
import Subscribe from '../muiHome/Subscribe'
import Loader from '../Pages/Loader/Loader';
import { Grid, Card, CardHeader, CardContent, Typography, Container, Stack } from '@mui/material';
import Recognization from '../muiHome/recognization';
import ScriptLoader from '../mencontact/scriptLoader';
import SpotLight from '../muiHome/SpotLight';
import MUICard from '../muiCommonComponent/muiCard';
import UpcoingEvents from '../muiHome/upcomingEvents';
import Notifications from '../muiHome/notifications';
function Home() {

  const [open, setOpen] = useState(true);
  const [popoopen, setPopoopen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [showContent, setShowContent] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handlePopClose = () => {
    setPopoopen(false);
  };


  const toggleContent = () => {
    setShowContent(!showContent);
  };
  useEffect(() => {
    setOpen(true);
    setPopoopen(true);
  }, []);



  return (
    <>
      <ScriptLoader />
      <Headerone />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Slider />
        </Grid>
        <Grid item xs={12} sm={12} lg={8} md={8}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <SpotLight />
            </Grid>

            <Grid item xs={12}>
              <About />
            </Grid>
            {/* <Grid item xs={12}>
        <Recognization/>
        </Grid> */}
            {/* <Grid item xs={12}>
              <Courses />
            </Grid> */}
            {/* <Grid item xs={12}>
        <Shep/>
        </Grid> */}
            <Grid item xs={12}>
              <Event />
            </Grid>
            <Grid item xs={12}>
              <PressRelease />
            </Grid>
            {/* 
        <Grid item xs={12}>
        <Frequently/>
        </Grid>
        <Grid item xs={12}>
        <Video/>
        </Grid>
        <Grid item xs={12}>
        <Testimonial/>
        </Grid> 
        <Grid item xs={12}>
        <Redslider/>
        </Grid>*/}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} md={4}>
          <Container>
            <Stack marginTop={0}>
              <MUICard title={'Notification'} content={Notifications()} />
            </Stack>
            {
            /*<Stack marginTop={5}>
              <MUICard title={'Upcoming Events'} content={UpcoingEvents()} />
            </Stack>  
             */
}
            <Stack marginTop={5}>
              <MUICard title={'Quick Links'} content={'No quick links available here!'} />
            </Stack>
            <br/>
            <video
      src="/convocation_video.mp4"
      autoPlay
      loop
      muted
      style={{ height: '600px', width: '100%' }}
    >
      Your browser does not support the video tag.
    </video>
          </Container>
        </Grid>
      </Grid>
      <br/><br/>
      <Grid container spacing={1} className=''>
      <Grid item xs={12}>
        <Homegallery/>
      </Grid>

     {/* <Grid item xs={12}>
        <Scholarship/>
      </Grid> */} 


  <div class="new_public_numbers">
            <div class="container">
            <div class="newpubl_nm">
            <div class="row">
                <div class="col-md-12">
                    <div class="newtitle">
                        <h3 class='title'>Our Publication Facts</h3>
                        <p class='title_descr'>Showcasing university research, publications, and patented innovations advancing academic excellence</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="one_fina_box">
                        <div class="icon-box1">
                            <span><img src={`${process.env.PUBLIC_URL}/assets/img/fact1.png`} alt="" /></span>
                        </div>
                        <div class="content">
                            <div class="souchea">-</div>
                            <div class="counter-title">Publication</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="one_fina_box">
                        <div class="icon-box1">
                            <span><img src={`${process.env.PUBLIC_URL}/assets/img/fact2.png`} alt="" /></span>
                        </div>
                        <div class="content">
                            <div class="souchea">-</div>
                            <div class="counter-title">Patents</div>
                        </div>
                    </div>
                </div>


            </div>
            </div>
            </div>
        </div>

        



      <Grid item xs={12}>
              <Testimonial />
      </Grid>
      
     
      <Grid item xs={12}>
        <Redslider/>
      </Grid>
            </Grid>
      {!showContent &&
        <div className={`toggle-button ${showContent ? 'active' : ''}`} onClick={toggleContent}>
          <button><img src='https://th.bing.com/th/id/R.bc30ad25a0c59ddc5f0d1811ed0c5d10?rik=FgJKsp4Mzc3Tfw&riu=http%3a%2f%2fwww.iband.com%2fclipart_holidays%2fnewfreeze.gif&ehk=NGphspN%2baipi73BD5mSdsag5aRUp1DqTytXqlhOe%2faw%3d&risl=&pid=ImgRaw&r=0' /> Latest Event</button>
        </div>
      }
      {/* Content */}
      <div className={`content-toggle ${showContent ? 'show' : ''}`}>
        <div className="content-toggle-header">
          {/* <h2>Annual Fest (Envisage 2k24)</h2> */}
          <button onClick={toggleContent} className="close-button">
            <CloseIcon />
          </button>
        </div>
        <p><a href='https://docs.google.com/forms/d/e/1FAIpQLSdPhHt-sD9CkvFeL8Dj0Jv8mGDOyu5eNtO-3E3Ryk1peAI8_g/viewform?usp=send_form' style={{ color: 'blue' }} target='_blank'>3rd International Conference on Recent advancements and innovations in health care</a> </p>

        <p><a href='/assets/pdf/3rd International Conference Brochure A4.pdf' style={{ color: 'blue' }} target='_blank'>Brochure</a> </p>


         
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth>

        <div className="contact-bg02" style={{ padding: "20px 50px " }}>
          <div className="section-title center-align text-center">
            <h2>Enquiry Form</h2>
          </div>
          <div className="npf_wgts" data-height="450px" data-w="dfc8352494c1e0ec69cfdd4fec2fbdfd"></div>
        </div>

        <IconButton
          edge="end"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            color: '#fff',
            top: 8,
            right: 25
          }}
        >
          <CloseIcon />
        </IconButton>

        {isLoading && <Loader />}
      </Dialog>

      <Dialog open={popoopen} onClose={handlePopClose} fullWidth>

        <div className="contact-bg02 hppybirthpopup" style={{ padding: "20px 50px " }}>
           
          <div className="happy_brith_msg" data-height="450px">
              {/*<img src="/assets/img/happy_birthday.jpg" />  */}
              <img src="/assets/img/New_event02.jpg" />


          </div>
        </div>

        <IconButton
          edge="end"
          onClick={handlePopClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            color: '#fff',
            top: 8,
            right: 25
          }}
        >
          <CloseIcon />
        </IconButton>

        {isLoading && <Loader />}
      </Dialog>


    </>
  )
}

export default Home