import React, { useEffect , useCallback} from 'react'; 
import Header from '../../headerone/Main';
import Bredcom from '../../Bredcom/Main';
import First from './first';
import TrackingScript from "./TrackingScript";

import NewMain from '../../headerone/newMain';

function ExaminationPolicy() {
   
  const handleTrackingComplete = useCallback(() => {
    console.log("Tracking complete. Redirecting...");
    window.location.href = "https://apply.mvn.edu.in"; // Perform redirection
  }, []);


  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Admission Form" />
      {/*<First/>  */}
      {/* <TrackingScript />   */}
       <TrackingScript onTrackingComplete={handleTrackingComplete} />
    </>
  );
}

export default ExaminationPolicy;
