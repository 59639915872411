import React, { useState } from 'react';
import { Card, CardContent, CardActionArea, Button, Typography, Avatar, Box, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const FacultyCard=({faculty})=>{
    const navigate=useNavigate()
    const handleDetail=(row)=>{
        const currentPath = window.location.pathname;
        const newPath = currentPath.replace('faculties', 'facultyDetail');
        navigate(newPath,{state:row});
    }
    return (
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
          <Box justifyContent="center" alignItems="center">
          <Avatar 
        src={faculty.img} 
        sx={{ 
            height: '140px', 
            width: '140px', 
            margin: 'auto', 
            objectFit: 'cover' 
        }} 
    />
          </Box>
            <CardContent>
            <Typography textAlign='center' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
                {faculty.name}
                </Typography>
                <Typography textAlign='center' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif"  >
                {faculty.position}
                </Typography>
                <Typography textAlign='center' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif"  >
                <a href={faculty.email} type='email'> {faculty.email}</a>
                </Typography>
            </CardContent>
            <CardActions>
    <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        width="100%" // Ensures the Box takes the full width of the parent
    >
         {/* <a href={faculty.viewdetail}> */}
       {/* <Button variant="contained" onClick={()=>handleDetail(faculty)}>
            View Detail
        </Button>*/}
        {/* </a> */}

        <Button variant="contained" onClick={()=>handleDetail(faculty)} >
            View Detail
        </Button>
    </Box>
</CardActions>

          </CardActionArea>
        </Card>
      );
}
export default FacultyCard;