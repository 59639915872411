import React, { useEffect } from "react";

const TrackingScript = ({ onTrackingComplete }) => {
  useEffect(() => {
    // Setting global variables
    window.npf_d = "https://apply.mvn.edu.in";
    window.npf_c = "6093";
    window.npf_m = "1";

    let retryCount = 0;
    const maxRetries = 3; // Maximum number of retries
    const retryDelay = 3000; // Delay between retries in milliseconds
    let scriptElement = null; // Maintain a reference to the script element

    const loadScript = () => {
      scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.async = true;
      scriptElement.src = "https://track.nopaperforms.com/js/track.js";

      // Log the script loading attempt
      console.log("Attempting to load script:", scriptElement.src);

      scriptElement.onload = () => {
        console.log("Tracking script loaded successfully.");
        if (onTrackingComplete) {
          onTrackingComplete(); // Trigger callback after successful load
        }
      };

      scriptElement.onerror = () => {
        console.error(`Script load failed. Retry attempt: ${retryCount + 1}`);
        if (retryCount < maxRetries) {
          retryCount++;
          setTimeout(loadScript, retryDelay);
        } else {
          console.error("Max retries reached. Unable to load the tracking script.");
        }
      };

      // Append the script to the document body
      document.body.appendChild(scriptElement);
    };

    // Check if the page is being loaded or refreshed
    if (performance.getEntriesByType("navigation")[0].type === "reload") {
      console.log("Page refreshed. No redirection applied.");
    }

    // Initiate script loading
    loadScript();

    // Cleanup function to remove script elements on component unmount
    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        console.log("Removing script on cleanup:", scriptElement.src);
        document.body.removeChild(scriptElement);
      }
    };
  }, [onTrackingComplete]); // Dependency array includes the callback

  return null; // This component only handles the script injection
};

export default TrackingScript;
