import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
function CallForapers() { 
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Publications" /> 
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                     

 

                    <br/> 
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Call for Papers</h3>
                                </div>

                                <table class="table table-bordered">
                                    <thead class="table-light head_red">
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">Document</th> 
                                            <th scope="col">Link to Download</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 

                                        <tr>
                                            <td>1</td>
                                            <td> CALL FOR RESEARCH PAPERS </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/CALL FOR RESEARCH PAPERS.pdf`} download >Download </a> </td>
                                        </tr>

                                        {/*<tr>
                                            <td>2</td>
                                            <td> EDITORIAL BOARD </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/EDITORIAL BOARD.pdf`} download >Download </a> </td>
                                        </tr>  */}

                                        <tr>
                                            <td>2</td>
                                            <td> Guidelines for Contributors  </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Guidelines for Contributors.pdf`} download >Download </a> </td>
                                        </tr>
                                            
                                        
                                          

                                        </tbody> 
 


                                 </table>   


                            </div>
                        </div>
                    </div>

                    <br/> 



 
 


                </div>
            </section>

    </>
  )
}

export default CallForapers