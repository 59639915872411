import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Footer from '../component/footer/Main'
import NewFooter from '../component/footer/newMain'
import Hometwo from '../component/hometwo/Main'
import Homethree from '../component/homethree/Main'
import About from '../component/About/Main'
import Courses from '../component/Courses/Main'
import Coursestwo from '../component/coursestwo/Main'
import Coursesdeatils from '../component/coursedetails/Main'
import Coursesdeatilstwo from '../component/coursedetailstwo/Main'
import Event from '../component/Pages/event/Main'
import Eventdeatils from '../component/Pages/eventdeatils/Main'
import Gallery from '../component/Pages/gallery/Main'
import Pricing from '../component/Pages/Pricing/Main'
import Faq from '../component/Pages/Faq/Main'
import Teacher from '../component/Pages/Teacher/Main'
import Teacherdeatils from '../component/Pages/teacherdetails/Main'
import Error from '../component/Pages/error/Main'
import Blog from '../component/Blog/Main'
import Blogdeatils from '../component/Blogdeatils/Main'
import Contact from '../component/contact/Main'
import Projectdeatils from '../component/Pages/Projectdeatils/Main'
import Home1 from '../component/home/Main';
import BankDetails from '../component/Pages/BankDetails/BankDetails';
import ApplicationPolicy from '../component/Pages/ApplicationPolicy/ApplicationPolicy';
import ReEvalutionRules from '../component/Pages/examinationPolicy.js/re-evalution-rules';
import PresidentsMessage from '../component/Pages/PresidentsMessage/Main';
import ViceChancellorMessage from '../component/Pages/ViceChancellorMessage';
import ProViceChancellorMessage from '../component/Pages/ProViceChancellorMessage';
import LifeAtMvn from '../component/Pages/LifeAtMvn';
import QrPayment from '../component/Pages/QrPayment';
import GalleryDetail from '../component/Pages/GalleryDetail';
import StudentActivities from '../component/Pages/StudentActivities';
import CRC from '../component/Pages/crc';
import CRCActivities from '../component/Pages/crcActivities';
import IQAC from '../component/Pages/iqac';
import CompositionOfIqac from '../component/Pages/compositionofiqac';
import FeeStructure from '../component/Pages/FeeStructure/FeeStructure';
import ComputerScienceEngineering from '../component/Pages/ComputerScienceEngineering/ComputerScienceEngineering';
import UnderConstructionPage from '../component/Pages/ComputerScienceEngineering/under-construction';
import LawSchool from '../component/Pages/LawSchool/LawSchool';
import AlliedHealthSciences from '../component/Pages/AlliedHealthSciences/AlliedHealthSciences';
import Pharmaceutical from '../component/Pages/Pharmaceutical/Pharmaceutical';
import BusinessManagement from '../component/Pages/BusinessManagement/BusinessManagement';
import HeathCampus from '../component/Pages/healthCampus/Main';
import IndustryExport from '../component/Pages/industryexport/Main';
import OurRecutiers from '../component/Pages/ourrecutires/Main';
import StudentTestimonial from '../component/Pages/studenttestiomial/Main';
import PlacementGlance from '../component/Pages/placementglance/Main';
import StudentGraviance from '../component/Pages/studentgrievanceform/Main';
import PhdProgram from '../component/Pages/phdprogram/Main';
import PhdGuidlines from '../component/Pages/phdguidlines/Main';
import PhdAwarded from '../component/Pages/phdawareded/Main';
import PhdList from '../component/Pages/phdlist/Main';
import FacultyResearch from '../component/Pages/facultyresearch/Main';
import RandDCell from '../component/Pages/randdcell/Main';
import CUET from '../component/Pages/cuet/Main';
import CUETCriteria from '../component/Pages/cuetcriteria/Main';
import GovermentReconigations from '../component/Pages/GovermentReconigations/Main';
import ExamNotice from '../component/Pages/ExamNotice/Main';
import BrochureDownload from '../component/Pages/ExamNotice/BrochureDownload';
import Infrastructure from '../component/Pages/infrastructure';
import Bca from '../component/Pages/bca';
import BScComputerScience from '../component/Pages/BScComputerScience';
import BTechCSE from '../component/Pages/BTechCSE';
import LLB from '../component/Pages/llb';
import LLM from '../component/Pages/llm';
import BALLB from '../component/Pages/BALLB';
import DMLT from '../component/Pages/DMLT';
import BPT from '../component/Pages/BPT';
import BscBiotechnology from '../component/Pages/BscBiotechnology';
import BscMedicalMicrobiology from '../component/Pages/BscMedicalMicrobiology';
import MscMicrobiology from '../component/Pages/MscMicrobiology';
import BMLT from '../component/Pages/BMLT';
import BPharm from '../component/Pages/BPharm';
import DPharm from '../component/Pages/DPharm';
import MPharm from '../component/Pages/MPharm';
import WhyMVN from '../component/Pages/whyMVN/main';
import ExaminationH from '../component/Pages/examinationPolicy.js/ExaminationH';
import ExaminationScheme from '../component/Pages/examinationPolicy.js/ExaminationScheme';
import ExaminationCommittee from '../component/Pages/examinationPolicy.js/ExaminationCommittee';
import ExaminationDatesheet from '../component/Pages/examinationPolicy.js/ExaminationDatesheet';
import ExaminationReEvaluation from '../component/Pages/examinationPolicy.js/ExaminationReEvaluation';
import ExaminationResults from '../component/Pages/examinationPolicy.js/ExaminationResults';
import NationalAcademicDepository from '../component/Pages/examinationPolicy.js/NationalAcademicDepository'; 
import AcedamicBankCredit from '../component/Pages/examinationPolicy.js/AcedamicBankCredit'; 

import ExaminationPolicy from '../component/Pages/examinationPolicy.js/main';
import FacultyAccomplishments from '../component/Pages/facultyAccomplishments/main';
import MBA from '../component/Pages/MBA';
import BBA from '../component/Pages/BBA';
import BBAHons from '../component/Pages/BBAHons';
import Bcom from '../component/Pages/Bcom';
import PersonalForm from '../component/Pages/admissionForm/main';

import BusinessVisionMission from '../component/Pages/BusinessManagement/Visionmission';
import BusinessPeoPoPsoBcom from '../component/Pages/BusinessManagement/PEOPOPSOBCom';
import BusinessPeoPoPsoMba from '../component/Pages/BusinessManagement/PEOPOPSOMba';
import BusinessPeoPoPsoBba from '../component/Pages/BusinessManagement/PEOPOPSOBba';
import BusinessPeoPoPsoBbaHons from '../component/Pages/BusinessManagement/PEOPOPSOBbaHons';

import PharmaceuticalVisionMission from '../component/Pages/Pharmaceutical/Visionmission';
import AlliedHealthBpt from '../component/Pages/AlliedHealthSciences/PEOPOPSOBpt';
import AlliedHealthBmlt from '../component/Pages/AlliedHealthSciences/PEOPOPSOBmlt';
import AlliedHealthBmm from '../component/Pages/AlliedHealthSciences/PEOPOPSOBmm';
import StudentDisciplineCommittee from '../component/Pages/StudentDisciplineCommittee';
import UniversityStudentsGrievancesRedressalCommittee from '../component/Pages/UniversityStudentsGrievancesRedressalCommittee';
import InternalComplaintsCommittee from '../component/Pages/InternalComplaintsCommittee';
import WomenCell from '../component/Pages/WomenCell';
import GamesandSportsCommittee from '../component/Pages/GamesandSportsCommittee';
import AntiRaggingCommittees from '../component/Pages/AntiRaggingCommittees';
import CulturalCommittee from '../component/Pages/CulturalCommittee';
import CanteenCommittee from '../component/Pages/CanteenCommittee';
import PurchaseCommittee from '../component/Pages/PurchaseCommittee';
import Career from '../component/Pages/career';
import ComputerVisionMission from '../component/Pages/ComputerScienceEngineering/ComputerVisionMission';
import PEOPOPSOCse from '../component/Pages/ComputerScienceEngineering/PEOPOPSOCse';
import PEOPOPSOBca from '../component/Pages/ComputerScienceEngineering/PEOPOPSOBca';
import ComputerMedia from '../component/Pages/ComputerScienceEngineering/ComputerMedia';
import AlliedHealthVisionMission from '../component/Pages/AlliedHealthSciences/AlliedHealthVisionMission';
import AlliedHealthActivities from '../component/Pages/AlliedHealthSciences/AlliedHealthActivities';
import AlliedHealthHospitals from '../component/Pages/AlliedHealthSciences/AlliedHealthHospitals';
import ArtsScience from '../component/Pages/ArtsScience/ArtsScience';


import PharmaScopeOfPharmacy from '../component/Pages/Pharmaceutical/PharmaScopeOfPharmacy';
import PharmaFaculty from '../component/Pages/Pharmaceutical/PharmaFaculty';
import PharmaMedia from '../component/Pages/Pharmaceutical/PharmaMedia';
import PharmaActivity from '../component/Pages/Pharmaceutical/PharmaActivity';
import PharmaActivityVideos from '../component/Pages/Pharmaceutical/PharmaActivityVideos';

import LawLegalVisits from '../component/Pages/LawSchool/LawLegalVisits'; 
import LawLegalActivities from '../component/Pages/LawSchool/LawLegalActivities';
import LawLegalAidClinic from  '../component/Pages/LawSchool/LawLegalAidClinic';
import LawMediaAttention from '../component/Pages/LawSchool/LawMediaAttention';
import LawInfrastructure from  '../component/Pages/LawSchool/LawInfrastructure';
import PEOPOPSOllm from  '../component/Pages/LawSchool/PEOPOPSOllm';
import PEOPOPSOBaLLB from '../component/Pages/LawSchool/PEOPOPSOBaLLB';
import LawVisionMission from '../component/Pages/LawSchool/LawVisionMission';
import LawScope from '../component/Pages/LawSchool/LawScope';
import PhdAdmissionForm from '../component/Pages/phdAdmissionForm/main';
import CSEConsultancy from '../component/Pages/ComputerScienceEngineering/Consultancy';
import CSEPatent from '../component/Pages/ComputerScienceEngineering/patents';
import CSEPublication from '../component/Pages/ComputerScienceEngineering/Publications';
import CSETeams from '../component/Pages/ComputerScienceEngineering/teams';
import BranchExaminationCell from '../component/Pages/BranchExamination/main';
import NewHome from '../component/home/home';
import MuiHome from '../component/home/muiHome';
import SOETHome from '../component/muiSOET/index';
import SOETInsights from '../component/muiSOET/insights';
import SOETVisionMission from '../component/muiSOET/vision_mission';
import SOETKnowledgePartners from '../component/muiSOET/knowledge_partners';
import SOETCSE from '../component/muiSOET/CSE';
import SOETMCOOS from '../component/muiSOET/mcoos';
import CSECourses from '../component/muiSOET/CSE/CSE_courses';
import SOETcivil from '../component/muiSOET/CIVIL/index';
import SOETME from '../component/muiSOET/ME';
import SOETECE from '../component/muiSOET/ECE';
import SAHSHome from '../component/muiSAHS/index';
import AHSCourses from '../component/muiSAHS/AHS';

import SOL from '../component/muiSOL';

import SBMC from '../component/muiSBMC';
import SOPS from '../component/muiSPS';
import SBMCCourses from '../component/muiSBMC/SBMC';
import SSH from '../component/muiSSH';
import FooterContent from '../component/Pages/footer_pages'
import Home from '../component/home/muiHome';
import Placements from '../component/muiPlacement/placements';
import TheCorporateResourceCentre from '../component/muiPlacement/TheCorporateResourceCentre';
import TeamCrc from '../component/muiPlacement/TeamCrc';
import StudentsCentreUpcomingJobOpenings from '../component/muiPlacement/StudentsCentreUpcomingJobOpenings';
import CorporateLaison from '../component/muiPlacement/CorporateLaison'; 
import Antiragging from '../component/muiPlacement/antiragging';
import NEP from '../component/muiNEP';

import ResearchDevelopmentHome from '../component/research_development/ResearchDevelopmentHome';
import AboutDeanRdc from '../component/research_development/AboutDeanRdc';
import ListofPhResearchScholars from '../component/research_development/ListofPhResearchScholars';
import RDDownloads from '../component/research_development/RDDownloads';
import Publications from '../component/publication/Publications';
import PublicationsFacultyDetail from '../component/publication/PublicationsFacultyDetail'; 
import WorkingPaperSeries from '../component/research_development/WorkingPaperSeries';
import CallForapers from '../component/research_development/CallForapers';
import EditorialBoard from '../component/research_development/EditorialBoard';

import EditorialFacultyDetail from "../component/publication/main";

function Index() {

  const location = useLocation();
  const path = location.pathname
  console.log('path::::::::',path)
  useEffect(() => {
    window.scroll(0, 0)
  }, [path]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/old-home" element={<Home1 />} />
        <Route path="/home-two" element={<Hometwo />} />
        <Route path="/home-three" element={<Homethree />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses-2" element={<Coursestwo />} />
        <Route path="/single-courses" element={<Coursesdeatils />} />
        <Route path="/bank-account-details" element={<BankDetails />} />
        <Route path="/fee-structure" element={<FeeStructure />} />
        <Route path="/computer-science-engineering" element={<ComputerScienceEngineering />} />
        <Route path="/under-construction" element={<UnderConstructionPage />} />
        <Route path="/school-of-arts-science-humanities" element={<ArtsScience />} />
        <Route path="/school-of-law" element={<LawSchool />} />

        <Route path="/school-of-law-legal-visits" element={<LawLegalVisits />} />
        <Route path="/school-of-law-legal-activities" element={<LawLegalActivities />} />
        <Route path="/school-of-law-legal-aid-clinic" element={<LawLegalAidClinic />} />
        <Route path="/school-of-law-legal-infrastructure" element={<LawInfrastructure />} />
        <Route path="/school-of-law-legal-media" element={<LawMediaAttention />} />
        <Route path="/school-of-law-legal-vision" element={<LawVisionMission />} />
        <Route path="/school-of-law-scope-of-law" element={<LawScope />} />

        <Route path="/school-of-law-legal-peo-po-pso-ba-llb" element={<PEOPOPSOBaLLB />} />
        <Route path="/school-of-law-legal-peo-po-pso-llm" element={<PEOPOPSOllm />} />
        
        <Route path="/qr_payment" element={<QrPayment />} />



        <Route path="/school-of-allied-health-sciences" element={<AlliedHealthSciences />} />
        <Route path="/school-of-pharmaceutical-sciences" element={<Pharmaceutical />} />
        <Route path="/school-of-business-management" element={<BusinessManagement />} />
        <Route path="/application-policy" element={<ApplicationPolicy />} />
        <Route path="/chancellors-message" element={<PresidentsMessage />} />
        <Route path="/vice-chancellor-message" element={<ViceChancellorMessage />} />
        <Route path="/pro-vice-chancellor-message" element={<ProViceChancellorMessage />} />
        <Route path="/life-at-mvn" element={<LifeAtMvn />} />
        <Route path="/gallery_details/:screenname" element={<GalleryDetail />} />
        <Route path="/sudent_activities" element={<StudentActivities />} />
        <Route path="/crc" element={<CRC />} />
        <Route path="/crc-activities" element={<CRCActivities />} />
        <Route path="/iqac" element={<IQAC />} />
        <Route path='/compositionofiqac' element={<CompositionOfIqac />} />
        <Route path="/single-courses-2" element={<Coursesdeatilstwo />} />
        <Route path="/event" element={<Event />} />
        <Route path="/single-event" element={<Eventdeatils />} />
        <Route path="/projects" element={<Gallery />} />
        <Route path="/single-projects" element={<Projectdeatils />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/team" element={<Teacher />} />
        <Route path="/team-single" element={<Teacherdeatils />} />
        <Route path="/404-error" element={<Error />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<Blogdeatils />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/health-campus" element={<HeathCampus />} />
        <Route path="/industry-export" element={<IndustryExport />} />
        <Route path="/our-recruiters" element={<OurRecutiers />} />
        <Route path="/students-testimonials" element={<StudentTestimonial />} />
        <Route path="/placement-at-a-glance" element={<PlacementGlance />} />
        <Route path="/student-grievance-form" element={<StudentGraviance />} />
        <Route path="/doctoral-programs" element={<PhdProgram />} />
        <Route path="/ph-d-guidelines" element={<PhdGuidlines />} />
        <Route path="/ph-d-awarded" element={<PhdAwarded />} />
        <Route path="/list-of-ph-d-research-scholars" element={<PhdList />} />
        <Route path="/faculty-research" element={<FacultyResearch />} />
        <Route path="/r-d-cell" element={<RandDCell />} />
        <Route path="/cuet-scholarship" element={<CUET />} />
        <Route path="/cuet-scholarships-criteria" element={<CUETCriteria />} />
        <Route path="/govermentReconigations" element={<GovermentReconigations />} />
        <Route path="/examnotice" element={<ExamNotice />} />
        <Route path="/brochure-download" element={<BrochureDownload />} />
        <Route path="/infrastructure" element={<Infrastructure />} />
        <Route path="/bScComputerScience" element={<BScComputerScience />} />
        <Route path="/bTechCSE" element={<BTechCSE />} />
        <Route path="/bca" element={<Bca />} />
        <Route path="/ballb" element={<BALLB />} />
        <Route path="/llb" element={<LLB />} />
        <Route path="/llm" element={<LLM />} />
        <Route path="/dmlt" element={<DMLT />} />
        <Route path="/bpt" element={<BPT />} />
        <Route path="/bscmedicalmicrobiology" element={<BscMedicalMicrobiology />} />
        <Route path="/bscbiotechnology" element={<BscBiotechnology />} />
        <Route path="/mscmicrobiology" element={<MscMicrobiology />} />
        <Route path="/bmlt" element={<BMLT />} />
        <Route path="/why-mvn" element={<WhyMVN />} />
        <Route path="/examination" element={<ExaminationH />} />
        <Route path="/examination-policy" element={<ExaminationPolicy />} />
        <Route path="/re-evalution-rules" element={<ReEvalutionRules />} />
        <Route path="/examination-scheme" element={<ExaminationScheme />} />
        <Route path="/examination-committee" element={<ExaminationCommittee />} />
        <Route path="/examination-date-sheet" element={<ExaminationDatesheet />} />
        <Route path="/examination-re-evaluation" element={<ExaminationReEvaluation />} />
        <Route path="/examination-results" element={<ExaminationResults />} />
        <Route path="/national-academic-depository-nad" element={<NationalAcademicDepository />} /> 
        <Route path="/acedamic-bank-credit" element={<AcedamicBankCredit />} /> 
        <Route path="/bpharm" element={<BPharm />} />
        <Route path="/dpharm" element={<DPharm />} />
        <Route path="/mpharm" element={<MPharm />} />
        <Route path="/faculty-accomplishments-certifications" element={<FacultyAccomplishments />} />
        <Route path="/bcom" element={<Bcom />} />
        <Route path="/bcom_hons" element={<Bcom />} />
        <Route path="/bba" element={<BBA />} />
        <Route path="/bbahons" element={<BBAHons />} />
        <Route path="/mba" element={<MBA />} />
        <Route path="/school-of-business-management-vision-mission" element={<BusinessVisionMission />} />
        <Route path="/school-of-business-management-peo-po-pso-b-com" element={<BusinessPeoPoPsoBcom />} />
        <Route path="/school-of-business-management-peo-po-pso-mba" element={<BusinessPeoPoPsoMba />} />
        <Route path="/school-of-business-management-peo-po-pso-bba" element={<BusinessPeoPoPsoBba />} />
        <Route path="/school-of-business-management-peo-po-pso-bba-hons" element={<BusinessPeoPoPsoBbaHons />} />
        <Route path="/school-of-pharmaceutical-sciences-vision-mission" element={<PharmaceuticalVisionMission />} />

        <Route path="/school-of-pharmaceutical-sciences-scope-of-pharmacy" element={<PharmaScopeOfPharmacy />} />
        <Route path="/school-of-pharmaceutical-sciences-faculty" element={<PharmaFaculty />} />
        <Route path="/school-of-pharmaceutical-sciences-activity" element={<PharmaActivity />} />
        <Route path="/school-of-pharmaceutical-sciences-media" element={<PharmaMedia />} />
        <Route path="/school-of-pharmaceutical-sciences-activity-videos" element={<PharmaActivityVideos />} />

        <Route path="/admission-form" element={<PersonalForm />} />
        <Route path="/school-of-allied-health-sciences-peo-po-pso-bpt" element={<AlliedHealthBpt />} />
        <Route path="/school-of-allied-health-sciences-peo-po-pso-bmm" element={<AlliedHealthBmm />} />
        <Route path="/school-of-allied-health-sciences-peo-po-pso-bmlt" element={<AlliedHealthBmlt />} />

        <Route path="/school-of-allied-health-sciences-hospitals" element={<AlliedHealthHospitals />} />
        <Route path="/school-of-allied-health-sciences-activities" element={<AlliedHealthActivities />} />
        <Route path="/school-of-allied-health-sciences-vision-mission" element={<AlliedHealthVisionMission />} />

        <Route path="/StudentDisciplineCommittee" element={<StudentDisciplineCommittee/>} />
        <Route path="/UniversityStudentsGrievancesRedressalCommittee" element={<UniversityStudentsGrievancesRedressalCommittee/>} />
        <Route path="/InternalComplaintsCommittee" element={<InternalComplaintsCommittee/>} />
        <Route path="/WomenCell" element={<WomenCell/>} />
        <Route path="/AntiRaggingCommittees" element={<AntiRaggingCommittees/>} />
        <Route path="/GamesandSportsCommittee" element={<GamesandSportsCommittee/>} />
        <Route path="/CulturalCommittee" element={<CulturalCommittee/>} />
        <Route path="/CanteenCommittee" element={<CanteenCommittee/>} />
        <Route path="/PurchaseCommittee" element={<PurchaseCommittee/>} /> 
        <Route path="/computer-science-engineering-vision-mission" element={<ComputerVisionMission/>} />

        <Route path="/computer-science-engineering-peo-po-pso-cse" element={<PEOPOPSOCse/>} />
        <Route path="/computer-science-engineering-peo-po-pso-bca" element={<PEOPOPSOBca/>} />
        <Route path="/computer-science-engineering-media" element={<ComputerMedia/>} />
        

        <Route path="/Career" element={<Career/>} /> 
        <Route path="/phd-admission-form" element={<PhdAdmissionForm />} />
        <Route path="/cse-consultancy" element={<CSEConsultancy />} />
        <Route path="/cse-patent" element={<CSEPatent />} />
        <Route path="/cse-publication" element={<CSEPublication />} />
        <Route path="/cse-teams" element={<CSETeams />} />
        <Route path="/branch-examination-cell" element={<BranchExaminationCell />} />
        <Route path="/new-home" element={<NewHome />} />
        <Route path="/mui-home" element={<MuiHome />} />

        <Route path="/school-of-engineering-technology" element={<SOETHome />} />
        <Route path="/soet/insights" element={<SOETInsights />} />
        <Route path="/soet/vision-mission" element={<SOETVisionMission />} />
        <Route path="/soet/knowledge-partners" element={<SOETKnowledgePartners />} />
        <Route path="/soet/mcoos" element={<SOETMCOOS />} />
        <Route path="/soet-cse/:screenname" element={<SOETCSE />} />
        <Route path="/soet-cse/course/:course" element={<CSECourses />} />
        <Route path="/soet-civil/:screenname" element={<SOETcivil />} />
        <Route path="/soet-me/:screenname" element={<SOETME />} />
        <Route path="/soet-ece/:screenname" element={<SOETECE />} />

        <Route path="/school-of-allied-health-sciences/:screenname" element={<SAHSHome />} />
        <Route path="/sahs/course/:screenname" element={<SAHSHome />} />
        <Route path="/school-of-law/:screenname" element={<SOL />} />

        <Route path="/school-of-business-management-and-commerce/:screenname" element={<SBMC />} />
        <Route path="/sbmc/course/:course" element={<SBMCCourses />} />
        <Route path="/school-Of-pharmaceutical-sciences/:screenname" element={<SOPS />} />

        <Route path="/school-of-arts-science-humanities/:screenname" element={<SSH />} />
        <Route path="/:screenname" element={<FooterContent/>} />
        <Route path="/placements" element={<Placements/>} />
        <Route path="/the-corporate-resource-centre" element={<TheCorporateResourceCentre/>} />
        <Route path="/team-crc" element={<TeamCrc/>} />
        <Route path="/students-centre-upcoming-job-openings" element={<StudentsCentreUpcomingJobOpenings/>} />
        <Route path="/corporate-laison" element={<CorporateLaison/>} /> 
        <Route path="/anti-ragging-information" element={<Antiragging/>} />
        <Route path="/nep" element={<NEP/>} />


        <Route path="/research_development/home" element={<ResearchDevelopmentHome />} />
        <Route path="/research_development/about_dean_rdc" element={<AboutDeanRdc />} />
        <Route path="/research_development/list-of-ph-d-research-scholars" element={<ListofPhResearchScholars />} />
        <Route path="/research_development/downloads" element={<RDDownloads />} />
        <Route path="/publications" element={<Publications />} /> 
        <Route path="/publications/school_alied_health_sciences" element={<Publications />} /> 
        <Route path="/publications/school-business-management-commerce" element={<Publications />} /> 
        <Route path="/publications/school-engineering-technology" element={<Publications />} /> 
        <Route path="/publications/school-law" element={<Publications />} /> 
        <Route path="/publications/school-pharmaceutical-sciences" element={<Publications />} /> 
        <Route path="/publications/ssh" element={<Publications />} /> 
        <Route path="/publications/:screenname/:screenname" element={<PublicationsFacultyDetail   />} />
        <Route path="/publications/facultyDetail" element={<EditorialFacultyDetail   />} />
        <Route path="/publications/working-paper-series" element={<WorkingPaperSeries />} />
        <Route path="/publications/call-for-papers" element={<CallForapers />} />
        <Route path="/publications/editorial_board" element={<EditorialBoard />} />



      </Routes>
      {/* {path=="/"?
      <Footer />
      : */}
      <NewFooter/>
      
      {/* } */}
    </>
  )
}

export default Index