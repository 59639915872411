import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardActionArea, Button, Typography, Avatar, Box, CardActions } from '@mui/material';

function EditorialBoard() { 


    const navigate=useNavigate()
        const handleDetail=(row)=>{
            const currentPath = window.location.pathname;
            const newPath = currentPath.replace('editorial_board', 'facultyDetail');
            navigate(newPath,{state:row});
        }


    /*const facultyListNew= [
        {
            img: '/assets/img/SOPS/faculty/yogender_singh.jpg',
            name: 'Prof. (Dr.) Yogendra Singh',
            position: 'Professor of Pharmacy & Registrar, MVN University, Palwal Haryana',
            email: 'registrar@mvn.edu.in',
            department: 'SOPS',
        },
        {
            img: '/assets/img/SAHS/faculty/biotech/vineet.jpg',
            name: 'Dr. Vineet Kumar',
            position: 'Professor & Dean, School of Allied Health Science',
            email: 'drvineet.kumar@mvn.edu.in',
            department: 'SAHS',
        },
        {
            img: '/assets/img/SAHS/faculty/biotech/dheeman.jpg',
            name: 'Dr. Shrivardhan Dheeman',
            position: 'Associate Professor, School of Allied Health Science',
            email: 'shrivardhan.dheeman@mvn.edu.in',
            department: 'SAHS',
        },
        {
            img: '/assets/img/SOPS/ashutoshupadhayay.jpg',
            name: 'Dr. Ashutosh Upadhayay',
            position: 'Professor & Dean, School of Pharmaceutical Science',
            email: 'dr.ashutoshupadhayay@mvn.edu.in',
            department: 'SOPS',
        },
        {
            img: '/assets/img/SOPS/faculty/jayamanti_pandit.jpg',
            name: 'Dr. Jayamanti Pandit',
            position: 'Associate Professor, School of Pharmaceutical Science',
            email: 'drjayamanti.pandit@mvn.edu.in',
            department: 'SOPS',
        },
        {
            img: '/assets/img/SOL/rahul_varshney.png',
            name: 'Dr. Rahul Varshney',
            position: 'Professor & Dean, School of Law',
            email: 'rahul.varshney@mvn.edu.in',
            department: 'SOL',
        },
        {
            img: '/assets/img/SOL/ramveer_singh.png',
            name: 'Dr. Ramveer Singh',
            position: 'Associate Professor, School of Law',
            email: 'Ramveer.singh@mvn.edu.in',
            department: 'SOL',
        },
        {
            img: '/assets/img/SBMC/vikram_manchanda.png',
            name: 'Dr. Vikram Manchanda',
            position: 'Associate Professor, School of Business Management & Commerce',
            email: 'vikram.manchanda@mvn.edu.in',
            department: 'SBMC',
        },
        {
            img: '/assets/img/SSH/kuldeep.png',
            name: 'Dr. Kuldeep Tanwar',
            position: 'Associate Professor, School of Science and Humanities',
            email: 'Kuldeep.tanwar@mvn.edu.in',
            department: 'SSH',
        },
    ];
        */
    const facultyList=[
        {
            img:'/assets/img/Varun_Sharma.jpg',
            name:'Mr. Varun Sharma ',
            position:'Chancellor, MVN University',
            email:'', 
            gs: "",
            linkedin: "",
            orcid: "",
            rg: "",
            website: "#",
            aoi: " ",
            education: "",
            re: "",
            te: "",
            iece: "",
            articles:
              "<li> </li>",
            books:
             "<li> </li>",
            chapters:
              " ",
            patents:
              "<li> </li>",
            membership:
              "<li> </li>",
            aa1: "",
            aa2: "",
            aa3: "",
            aa4: "",
            aa5: "",
            aa6: "",
            link: "no",
        },

            {
                img:'/assets/img/arun_garg.jpg',
                name:'Dr. Arun Garg ',
                position:'Vice Chancellor, MVN University',
                email:'vc@mvn.edu.in', 
                gs: "",
                linkedin: "",
                orcid: "",
                rg: "",
                website: "#",
                aoi: " ",
                education: "",
                re: "",
                te: "",
                iece: "",
                articles:
                  "<li> </li>",
                books:
                 "<li> </li>",
                chapters:
                  " ",
                patents:
                  "<li> </li>",
                membership:
                  "<li> </li>",
                aa1: "",
                aa2: "",
                aa3: "",
                aa4: "",
                aa5: "",
                aa6: "",
                link: "no",},
                


                {img:'/assets/img/SBMC/np_singh.png',name:'N.P. Singh',position:'Pro-Vice Chancellor',email:'dr.npsingh@mvn.edu.in',gs: "https://scholar.google.com/citations?user=cUgmsSMAAAAJ&hl=en",
                    linkedin: "https://www.linkedin.com/in/netra-pal-singh-a6a6253/",
                    orcid: "https://orcid.org/0000-0002-3006-9522",
                    rg: "https://www.researchgate.net/profile/Netra-Singh ",
                    website: "#",
                    aoi: "IT Management/ Machine Learning/AI ",
                    education: " PhD (Statistics)",
                    re: "42",
                    te: "42",
                    iece: "N/A",
                    articles:
                      "<li>1. Singh, N. P. (2007). Consumer Perception about fast food in India, (with Anita Goyal). British Food Journal, Vol.109 (2): 182-195 (Citations -462: IF 4.09).</li><li>2. Singh, N.P. (2007). Online Frauds in banks with Phishing, The Journal of Internet and Banking Commerce, 12 (2), 1-27.(Citations-66) </li><li>3. Dahiya, Shashi; S. S. Handa, and N P Singh (2017). A feature selection enabled hybrid bagging algorithm for credit risk evaluation, Expert Systems, 34 (6): 1-11 (Citations-58- IF – 2.812) </li>          "
                      ,
                    books:
                      "N/A",
                    chapters:
                      "1. Gupta, Nakul and Singh N.P. (2019). When Artificial Intelligence Meets Augmented Reality Management and Business Education in the Time of Artificial Intelligence, pages 195–210. Copyright © 2019 by Information Age Publishing (Book Edited by Dr. Agata and Dr. Wolfgang).",
                    patents:
                      "N/A",
                    membership:
                      "<li>1. Life Member of Indian Science Congress Association</li><li>2. Life Member of Indian Society of Agricultural Statistics</li><li>3. Member of AIMA, IEEE</li>",
                    aa1: "1. University Scholarship by Haryana Agricultural University during Ph.D. 1977.        ",
                    aa2: "2. Council of Scientific & Industrial Research (CSIR): Junior Research Fellowship 1978- 1981        ",
                    aa3: "3. Young Scientist Award by Indian Scoiety of Agricultural Research 1989",
                    aa4: "",
                    aa5: "",
                    aa6: "",},
                    
                    /*{img:'/assets/img/SBMC/manchanda.jpg',name:'Dr. S.C. Manchanda',position:'Professor(Management)',email:' prof.sc.manchanda@gmail.com',gs: "#",
                    linkedin: "#",
                    orcid: "#",
                    rg: "#",
                    website: "#",
                    aoi: "Quantitative Techniques, Finance, HR",
                    education:"PhD (Management)",
                    re: "3",
                    te: "17",
                    iece: "35",
                    articles:
                      "N/A",
                    books:
                      "<li>1. Economics for Engineers</li><li>2. Security Analysis and Portfolio Management</li><li>3. Operation Research and Business Statistics – Under Publishing </li>",
                    chapters:
                      "N/A",
                    patents:
                      "N/A",
                    membership:
                      "N/A",
                    aa1: "N/A",
                    aa2: "",
                    aa3: "",
                    aa4: "",
                    aa5: "",
                    aa6: "",},
                    */

                    {
                        img:'/assets/img/KishorKumarJha.jpg',
                        name:'Dr. Kishor Kr. Jha (Chief Librarian)',
                        position:'Coordinator',
                        email:'kk.jha@mvn.edu.in', 
                        gs: "",
                        linkedin: "https://www.linkedin.com/in/dr-kishor-kumar-jha-870a431a6/",
                        orcid: "",
                        rg: "https://www.researchgate.net/profile/jhakk.1979@gmail.com",
                        website: "NA",
                        aoi: "Library and Information Science ",
                        education: "PhD (Library and Information Science)",
                        re: "NA",
                        te: "NA",
                        iece: "20",
                        articles:
                          "<li>a)	Kishor Kumar Jha and Sanjay Kumar Sharma, Access Pattern of e-resources in Delhi NCR Libraries: a study, International Research Journal of Management Sociology & Humanities, Vol. 11 Issue 10, 2020, ISSN: 2348 9359 </li>Kishor Kumar Jha and Rama Nand Malviya, Access and use of e-resources in Engineering and Management College libraries in NCR, Vidyawarta International Multilingual Research Journal, Issue 17, Vol. 02 Jan. To Mar. 2017. ISSN: 2319 9318<li> </li><li> Kishor Kumar Jha and Deepak Prasad Knowledge Management: Issues and Challenges, Proceedings of the National Conference on Information Literacy Skills for College Librarians in Digital Environment</li>",
                        books:
                         "<li> </li>",
                        chapters:
                          "NA ",
                        patents:
                          "<li> </li>",
                        membership:
                          "<li>Haryana Library Association  </li><li> Indian Library Association (ILA)</li><li> Library Professional Association (LPA)	</li>",
                        aa1: "",
                        aa2: "",
                        aa3: "",
                        aa4: "",
                        aa5: "",
                        aa6: "",
                        link: "yes",},
















        {img:'/assets/img/SOPS/faculty/yogender_singh.jpg',name:'Prof. (Dr.) Yogendra Singh ',position:'Professor of Pharmacy & Registrar, MVN University, Palwal Haryana',email:'registrar@mvn.edu.in', gs: "https://scholar.google.com/citations?user=8TCZslQAAAAJ&hl=en",
        linkedin: " https://www.linkedin.com/in/prof-dr-yogendra-singh-20102445/",
        orcid: "https://orcid.org/0000-0002-6117-1941",
        rg: "https://www.researchgate.net/profile/Yogendra-Singh-56",
        website: "#",
        aoi: "Pharmacognosy & Phytochemistry",
        education: "Ph.D. in Pharmaceutical Sciences",
        re: "3",
        te: "16",
        iece: "3",
        articles:
          "<li> Y Singh, SK Paswan, R Kumar, MK Otia, S Acharya, D Kumar, “ Plant & Its Derivative Shows Therapeutic Activity on Neuroprotective Effect,” Journal for Research in Applied Sciences and Biotechnology, 1(2), 10-24, 2023., Y Singh, KK Das, MT Thelly, B Kumar, A Roy,</li><li> “ Phytochemical Study And In Vitro Anti-Diabetic Activity Of Eucalyptus And Lemon Oils,”  European Chemical Bulletin 12 (6), 2105 – 2116, 2023., R Garg, M Piplani, Y Singh, P Bhateja, R Rana,</li><li> “ An Overview of Integrated Risk Factors with Prevention and Prevalence of Asthma at the Global Level,”  Current Traditional Medicine 10 (4), 71-81, 2024</li>",
        books:
          "<li>Dr. Sadhu Venkateswara Rao, Prof.(Dr.) Yogendra Singh, Prof. (Dr.) Chennu MM Prasada Rao and Dr. Jiwan Premchand Lavande. Pharmaceutical Technology, ISBN: 9789395468671, AGPH Books (Academic Guru Publishing House), 2022.,</li><li> Prof.(Dr.) Yogendra Singh, Mr. Pankaj Bhateja, Dr. Rajesh Kumar, Ms. Neeru Malik. A Comprehensive Book of Pharmacy, ISBN: 9769391385361, Corvette Press, 2023,</li><li> Prof. Brijesh Kumar, Dr. Rajesh Kumar, Dr. Vijay Kumar Bansal, Prof.(Dr.) Yogendra Singh. Medicinal Chemistry,  IBS Publication, 2023.,</li><li> Prof. Brijesh Kumar, Dr. Rajesh Kumar, Dr. Vijay Kumar Bansal, Prof.(Dr.) Yogendra Singh. Pharmaceutical Inorganic Chemistry, IBS Publication, 2023</li>",
        chapters:
          "Rahesh Kumar, Ygendra Singh, Mona Piplani, Pankaj Bhateja, Ruchika Garg & Brijesh Kumar  “ The Chemistry and Pharmacological Study of Copaifera spp. Oils,”  The Chemistry Inside Spices & Herbs, vol. 4,  78-116, 2024,  Bentham Sciences Publishers.",
        patents:
          "<li>Model for Detection of Security Attack in IoT Enabled Environment”, Inventors:  Abdul Hannan Abdul Mannan Shaikh, Dr V. Christo Selvan, Dr. B. V. V. Siva Prasad, Dr. Namita Mishra, Dr. Pratibha Teotia, Dr. Priyanka Jain, Dr. Samala Nagaraj, Dr. Samriti Mahajan, Dr. Shashi Prabha Nagendra, Dr. Sita Rani, Dr. Yogendra Singh, Publication Date:  28 Sept. 2022, Application No.:  2022/07611 (South Africa Patent),</li><li> Incubator for Controlling Temperature & Humidity for the Cultivation and Propagation of Plant Cell Culture”, Inventors: Prof.(Dr) Bhupendra Kumawat, Dr. Yogendra Singh, Bharat Goswami, Arjun Kasnia, Manoj Khardia, Publication Date: 5 July 2023, Application No.: 389630-001.</li><li> Artificial Intelligence Based Muffle Furnace”, Inventors: Prof.(Dr) Yogendra Singh, Smita Acharya, Ms Rekha Rani, Publication Date: 29 March 2024, Application No.:  404930-001</li>",
        membership:
          "<li>Registered Pharmacist of Rajasthan Pharmacy Council. Reg. No. 18658,</li><li>  Life member of “Association of Pharmacy Teachers of India (APTI)”,</li><li> Life member of “Association of Pharmacy Professionals (APP)”,</li><li> Life member of “Indian Pharmacy Graduates’ Association (IPGA)”</li>",
        aa1: "“Prof. M.L. Schroff Pharma Recognition Award” on the Occasion of Teachers’ Day on 05th Sept 2022 by Team Pharmalok.",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",
        link: "yes",},
        
        {img:'/assets/img/SAHS/faculty/biotech/vineet.jpg',name:'Dr. Vineet Kumar',position:'Professor & Dean, School of Allied Health Science',email:'drvineet.kumar@mvn.edu.in',gs: "https://scholar.google.com.sg/citations?user=oLFkMRwAAAAJ&hl=en",
            linkedin: "https://sg.linkedin.com/in/vineet-kumar-phd-52431111",
            orcid: "#",
            rg: "https://www.researchgate.net/profile/Vineet-Kumar-8",
            website: "https://vidwan.inflibnet.ac.in/profile/516789",
            aoi: "Microbiology & Biotechnology",
            education: " M.Sc., Biotechnology, Ph.D. Biomedical Sciences (Delhi University), Post-Doc Fellow – NUS, Singapore & NTU, Singapore",
            re: "15",
            te: "5",
            iece: "N/A",
            articles:
              "<li>Vasudevan, A*., Kumar, V*., Ning Chiang, Y., Yew, J. Y., Cheemadan, S., & Vyas, A. (2015). α2u-globulins mediate manipulation of host attractiveness in Toxoplasma gondii–Rattus novergicus association. The ISME journal, 9(9), 2112-2115. (*First Co-author) IF: 11.0</li><li>Lim, A., Kumar, V., Hari Dass, S. A., & Vyas, A. (2013). Toxoplasma gondii infection enhances testicular steroidogenesis in rats. Molecular ecology, 22(1), 102-110. IF: 4.9</li><li>Chew, K. C*., Kumar, V*, & Tan, A. Y. (2022). Different Excitation–Inhibition Correlations Between Spontaneous and Tone-evoked Activity in Primary Auditory Cortex Neurons. Neuroscience, 496, 205-218. (*First Co-author) IF: 3.3</li>",
            books:
              "N/A",
            chapters:
              "Verma, M., & Kumar, V. (2018). Epigenetic drugs for cancer and precision medicine. In Epigenetics of aging and longevity (pp. 439-451). Academic Press.",
            patents:
              "N/A",
            membership:
              "<li>SFN, Singapore</li><li>Association of Microbiologist of India</li>",
            aa1: "Qualified the CSIR-UGC conducted NET exam June 2002 and have become eligible for JRF-Fellowship",
            aa2: "under CSIR schemes and become eligible for lectureship Qualified the ICMR conducted ICMR-JRF exam July 2002 and have become eligible for JRF-Fellowship",
            aa3: "Qualified the ICAR conducted ASR NET exam Oct 2001 and have become eligible for Lectureship in Biotechnology.",
            aa4: "Young investigator travel grant from EMBO to present a paper at the University of Oxford, UK in 2009",
            aa5: "Qualified for Erasmus Mundus Post-Doctoral Program - 2009",
            aa6: "",
            link: "yes",},

            {img:'/assets/img/SAHS/faculty/biotech/dheeman.jpg',name:'Dr. Shrivardhan Dheeman',position:'Associate Professor, School of Allied Health Science',email:'shrivardhan.dheeman@mvn.edu.in ',gs: "#",
                linkedin: "#",
                orcid: "#",
                rg: "#",
                website: "#",
                aoi: " Plant-Microbe Interaction",
                education: "Ph.D. Microbiology, M.Sc. Microbiology",
                re: "N/A",
                te: "8",
                iece: "N/A",
                articles:
                  "15 Research Articles / 05 Review Articles",
                books:
                  "04 books",
                chapters:
                  "20 book chapters",
                patents:
                  "N/A",
                membership:
                  "N/A",
                aa1: "N/A",
                aa2: "",
                aa3: "",
                aa4: "",
                aa5: "",
                aa6: "",
                link: "yes",},

        {img:'/assets/img/SOPS/ashutoshupadhayay.jpg',name:'Dr. Ashutosh Upadhayay',position:'Professor & Dean, School of Pharmaceutical Science',email:'dr.ashutoshupadhayay@mvn.edu.in',
          gs: "https://scholar.googleh.com/citations?user=h3LRiD0AAAAJ&hl=en&authuser=1",
          linkedin: "",
          orcid: "https://orcid.org/0009-0002-8848-2149",
          rg: "https://www.researchgate.net/profile/dr.ashutoshupadhayay",
          website: "#",
          aoi: "Clinical Research, Antidiabetic, Anti-hyperlipidemic, Toxicology",
          education: "PhD (Pharmacology)",
          re: "1",
          te: "18",
          iece: "2",
          articles:
            "<li>1.	Correlation between demographic characteristics and degree of illness among asthmatic patients in the panchkula region, European Chemical Bulletin, 2023 12 (special issue 5), 4868-4874. </li>2.	Clinical trials in Pharmacy: Methodology and Ethics considerations, European Chemical Bulletin, 2023 (special issue 9)2971-2983 <li> </li>3.	A review on comparison of allopathic medicines to other drug therapies in the management of asthama, Infect Disord Drug targets, 24(2);2024, Article ID: e201023222496 <li> </li><li> 4.	The influence of Phytoconstituents for the management of antipsoriatic activity in various animal models, Anti-inflamma. & Anti-allerg. Agents in Med. Chem ,07;2024 DOI: 10.2174/0118715230320581240711063558 </li>",
          books:
            "<li>1.	A Text book of Pharmaceutical Jurisprudence, Nitya Publication, Bhopal, MP, India. ISBN: 9789394894495  </li><li>2.	A practical book of Pharmacology- II, TDC Publication, Gurugram, Haryana, India. ISBN: 9789391864705  </li>",
          chapters:
            "<li>NA</li>",
          patents:
            "<li>Polyherbal formulation for wound healing and a process for the preparation there of Appl. No. 202311004263A, Issue No. 4/2023, publication date 27/01/2023</li>",
          membership:
            "<li>i.	Life Member, Association of the Pharmaceutical Teachers of India (APTI). </li><li>ii.	Registered Pharmacist of Rajasthan pharmacy council. </li><li>iii.	Life member, Indian Pharmacy Graduate Association (IPGA) </li>",
          aa1: "1.	Awarded by APP Best Teacher Award 2014 in National seminar(FPER: GC2014) November 16, 2014 at Alwar Pharmacy College, Alwar ",
          aa2: "2.	Awarded by Best Teacher Award 2022 for outstanding achievement in NIMS University.",
          aa3: "3.	Conducted Pre conference workshop on ‘Hands on training on ADR reporting’ organized by NIMS institute of pharmacy, NIMS university, Jaipur on dated 25th November 2022 as a registration incharge.",
          aa4: "4.	Conducted International conference on ‘Fostering high quality clinical research for a healthier world” organised by NIMS institute of pharmacy, NIMS university, Jaipur on dated 26 november 2022 registration incharge. ",
          aa5: "5.	Conducted Workshop-cum hands on practice on “Artificial intelligence in new molecular discoveries” as a organizing secretary (A part of social scientific responsibility activity of SERB project) organised by center of inter-dicilplinary biomedical research (CIBR), Adesh university, bathinda, punjab on dated 27-29 april 2023.",
          aa6: "",
          link: "yes",
          },


        {img:'/assets/img/SOPS/faculty/jayamanti_pandit.jpg',name:'Dr. Jayamanti Pandit',position:'Associate Professor, School of Pharmaceutical Science',email:' drjayamanti.pandit@mvn.edu.in',gs: "https://scholar.google.com/citations?user=K9IhfIQAAAAJ&hl=en",
        linkedin: "https://www.linkedin.com/in/jayamanti-pandit-32154022/",
        orcid: "https://orcid.org/0000-0001-5816-5797      ",
        rg: "https://www.researchgate.net/profile/Jayamanti-Pandit",
        website: "#",
        aoi: "Nanotechnology, Ocular delivery, Transdermal delivery      ",
        education: "PhD (Pharmaceutics)      ",
        re: "9",
        te: "8",
        iece: "N/A",
        articles:
          "<li>Pandit, J., Chaudhary, N., Emad, N.A., Ahmad, S., Solanki, P., Aqil, M., Sultana, Y. and Solanki, P., 2023. Fenofibrate loaded nanofibers based thermo-responsive gel for ocular delivery: Formulation development, characterization and in vitro toxicity study. Journal of Drug Delivery Science and Technology, 89, p.104935. <li><li>Pandit, J., Sultana, Y. and Aqil, M., 2021. Chitosan coated nanoparticles for efficient delivery of bevacizumab in the posterior ocular tissues via subconjunctival administration. Carbohydrate Polymers, 267, p.118217..</li><li>Pandit, J., Sultana, Y. and Aqil, M., 2017. Chitosan-coated PLGA nanoparticles of bevacizumab as novel drug delivery to target retina: optimization, characterization, and in vitro toxicity evaluation. Artificial cells, nanomedicine, and biotechnology, 45(7), pp.1397-1407.</li><li>Pandit, J., Sultana, Y. and Aqil, M., 2021. Chitosan coated nanoparticles for efficient delivery of bevacizumab in the posterior ocular tissues via subconjunctival administration. Carbohydrate Polymers, 267, p.118217..</li>",
        books:
          "N/A",
        chapters:
          "<li>https://scholar.google.com/scholar?oi=bibs&cluster=1893559369256021131&btnI=1&hl=en</li><li>Pandit, Jayamanti*, Md Sabir Alam, Md Noushad Javed, Aafrin Waziri, and Syed Sarim Imam. \"Emerging Roles of Carbon Nanohorns As Sustainable Nanomaterials in Sensor, Catalyst, and Biomedical Applications.\" In Handbook of Green and Sustainable Nanotechnology: Fundamentals, Developments and Applications, pp. 1-27. Cham: Springer International Publishing, 2023.</li><li>Jahangir, Mohammed Asadullah, Syed Sarim Imam, and Jayamanti Pandit*. \"Impact and Role of Stability Studies in Parenteral Product Development.\" In Micro-and Nanotechnologies-Based Product Development, pp. 225-234. CRC Press, 2021</li><li>Pandit, Jayamanti, Md Sabir Alam, Jamilur R. Ansari, Monisha Singhal, Nidhi Gupta, Aafrin Waziri, Kajal Sharma, and Faheem Hyder Pottoo. \"Multifaced applications of nanoparticles in biological science.\" In Nanomaterials in the Battle Against Pathogens and Disease Vectors, pp. 17-50. CRC Press, 2022.</li>        ",
        patents:
          "N/A",
        membership:
          "<li>Life Member, Association of the Pharmaceutical Teachers of India (APTI). </li><li>Registered Pharmacist of M.P state.</li><li> Registered Patent Agent, India</li><li>Life member, Indian Pharmacy Graduate Association (IPGA)</li>        ",
        aa1: "Awarded certificate of appreciation by Jamia Hamdard for high impact factor publications in the year 2018, 2022, and 2024.      ",
        aa2: "Junior Research Fellowship (UGC) for Post-Graduation at Dr. H. S. Gour University, Sagar, for the period 2003-2005. ",
        aa3: "CSIR-SRF for PhD at Jamia Hamdard, Delhi for the period 1st April 2013- 31st March 2017.      ",
        aa4: "Awardees of prestigious “Women-Scientist Scholarship Scheme WOS-C (KIRAN IPR) in Intellectual Property Rights granted by Patent Facilitating Centre, Technology Information Forecasting Assessment Council (TIFAC), Department of Science and Technology, Govt. of India.      ",
        aa5: "Awarded research fellowship as Research Associate (ICMR) in 2020.      ",
        aa6: "Qualified Patent agent examination in first attempt in 2022 and registered as a Patent agent.      ",
        link: "yes",}, 
        
        {img:'/assets/img/SOL/rahul_varshney.png',name:'Dr. Rahul Varshney',position:'Professor & Dean, School of Law',email:'rahul.varshney@mvn.edu.in',
            name: "Dr. Rahul Varshney",
            gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=Prof+Rahul+Varshney&nuan-1=Dr+Rahul+Varshney&authuser=2#",
            linkedin: " https://www.linkedin.com/in/dr-rahul-varshney-26550650",
            orcid: "https://orcid.org/0000-0003-2000-5995",
            rg: "https://www.researchgate.net/profile/Rahul-Varshney-4",
            website: "#",
            aoi: "Corporate and Commercial Law, Consumer Law, Competition Law, Jurisprudence, Law of Torts ",
            education: "B.Sc. (PCM), LL.B., LL.M., Ph.D. (Law) ",
            re: "7",
            te: "20",
            iece: "2",
            articles: "“Digital Discourse: A Look on Social Media Regulations” Published in International Research Mirror (An International Peer Reviewed, Refereed, Indexed, Interdisciplinary, Multilingual, Monthly Research Journal), ISSN (P)-2250-253x, ISSN (E)-2320-544x, November, 2023, Volume-I, ISSUE-11 and Page No.41 to 47., “Executive Legislation in India: Legality of Ordinance Making Power” Published in Praxis International Journal of Social Science and Literature, ISSN No: 2581-6675, Volume-6, Issue-8, August 2023.“Trail by Media And Its Effect On Fair Trail” Published in Indian Journal of Integrated Research in Law, ISSN-2583-0538, Volume-II, ISSUE-I,  January, 2022.",
            books: "N/A",
            chapters: "N/A",
            patents: "N/A",
            membership: "Member of Bar Council of Uttar Pradesh.",
            aa1: "Best Employee of the Year 2014 (Harlal School of Law, Greater Noida) ",
            aa2: "Best All Rounder of the Year 2013 (Harlal School of Law, Greater Noida)",
            aa3: "All round Performer of the Year 2011-12 (Harlal School of Law, Greater Noida)",
            aa4: "",
            aa5: "",
            aa6: "",
            link: "yes",},

            {img:'/assets/img/SOL/ramveer_singh.png',name:'Dr. Ramveer Singh',position:'Associate Professor, School of Law',email:'ramveer.singh@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=Dr.+Ramveer+Singh%23",
                linkedin: "https://www.linkedin.com/in/dr-ramveer-singh-b96a281a6/",
                orcid: "https://orcid.org/0000-0002-3359-121X",
                rg: "https://www.researchgate.net/profile/Ramveer-Singh-2",
                website: "#",
                aoi: "Criminal Law, Constitutional Law, Family Law.",
                education: "PhD (Criminal Law)",
                re: "10",
                te: "14",
                iece: "17",
                articles: "<li>“Hired Womb (Surrogate Mother) - Many Questions?” Published in International Journal of Legal Research and Studies, UGC Sr. No.1224 and Journal No. 48489, ISSN No.-2456-608X, Volume 3- Issue 1, January- March 2018, Page No.37 to 40.</li><li>“Adultery: No Longer a Criminal Affair” Published in Shodh Samiksha Aur Mulyankan, UGC Regd. No.41004, International Indexed, Peer Reviewed & Refereed Journal, ISSN-0974-2832(Print), E-ISSN-2320-5472, April, 2019, ISSUE-123 and Page No.27 to 30.</li><li>“Mob Lynching: A New Challenge” Published in Research Analysis & Evaluation, UGC Regd. No.41022, International Indexed Peer Review & Research Journal, ISSN-0975-3486(Print), E-ISSN-2320-5482, April, 2019, ISSUE-115 and Page No.04 to 07.</li><li>Note: - Total Articles and Research Papers published in various UGC Listed journals - 20</li>      ",
                books: "N/A",
                chapters: "<li> “Apprehension of Dire Consequences of Deteriorating Environment in Future: A Critical Study” Published in Environment Impact Assessment by Prof. Vimlendu Tayal, ISBN: 978-81-8198-385-5, M/s University Book House (Pvt.) Ltd. 79, Chaura Rasta, Jaipur-3, Page No.-240 – 245.</li><li>“Important Role of Judiciary in Protecting Child Rights” Published in Child Rights & Child Protection Issues, Challenges and Strategies by Dr. Sitaram & Dr. Mohammad Azvar Khan, ISBN: 978-81-949509-2-9, Published by: Satyam Law International, 2/13, Ansari Road, Daryaganj, New Delhi-110002, India. Page No.-129 – 136.</li>",
                patents: "N/A",
                membership: "Member of All India Bar Council.",
                aa1: "National Eligibility Test (N.E.T.) by Union Grant Commission (UGC) India, 2013.",
                aa2: "Common Eligibility Test (C.E.T.) by Ram Manohar Lohia Avadh University, Ayodhya India from 2012.",
                aa3: "",
                aa4: "",
                aa5: "",
                aa6: "",
                link: "yes",
            },


                {img:'/assets/img/SBMC/vikram_manchanda.png',name:'Dr. Vikram Manchanda',position:'Associate Professor, School of Business Management & Commerce',email:'vikram.manchanda@mvn.edu.in', gs: "#",
                    linkedin: "https://www.linkedin.com/in/vikm ",
                    orcid: "https://orcid.org/0009-0002-1391-9588   ",
                    rg: "https://www.researchgate.net/profile/Vikram-Manchanda-2",
                    website: "#",
                    aoi: " Marketing, Healthtech, FinTech, Strategy ",
                    education: "B.E. (E&C), MBA, Ph.D.",
                    re: "8",
                    te: "4.5",
                    iece: "23",
                    articles:
                      "1. Information Technology in Healthcare in India: A Bibliometric Analysis.",
                    books:
                      "N/A",
                    chapters:
                      "N/A",
                    patents:
                      "N/A",
                    membership:
                      "N/A",
                    aa1: "1. Best Research Award in MBA.",
                    aa2: "2. Topper in Strategic Management in MBA.",
                    aa3: "3. Distinction holder in Ph.D. Course work.",
                    aa4: "4. Trained in “Six Thinking Hats Technique” by Dr. Edward de Bono.",
                    aa5: "5. Consultant to various startups in the area of Healthcare and Martech.  ",
                    aa6: "",
                    link: "yes",
                },

                    {img:'/assets/img/SSH/kuldeep.png',name:'Dr. Kuldeep Tanwar',position:'Associate Professor, School of Science and Humanities',email:'kuldeep.tanwar@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_articles&amp;hl=en&amp;imq=Kuldeep+Tanwar#",
                        linkedin: "https://www.linkedin.com/in/kuldeep-tanwar-",
                        orcid: "https://orcid.org/0000-0002-0381-6043",
                        rg: "https://www.researchgate.net/profile/Kuldeep-Tanwar-2",
                        website: "#",
                        aoi: "Operations Research",
                        education: "Ph.D. (Mathematics)",
                        re: "7",
                        te: "15",
                        iece: "N/A",
                        articles:
                          "<li>i. Tanwar K., Chauhan S. K. (2020), “Time-Cost Solution Pairs in Multi-Index Bulk Transportation Problem”. Communications in Computer and Information Science, Vol. 1230.</li><li> ii. Singh, S., Chauhan, S. K. and Kuldeep. (2018), “A Bi-Criteria Multi-Index Bulk Transportation Problem”. Annals of Pure and Applied Mathematics, Vol. 24.</li><li> iii. Tanwar, K. and Chauhan, S. K. (2021), “A Simple Algorithm for Fuzzy Time Minimizing Bulk Transportation Problem”. IEEE Transactions on Reliability, Infocom Technologies and Optimization.</li>",
                        books:
                          "N/A",
                        chapters:
                          "N/A",
                        patents:
                          "N/A",
                        membership:
                          "N/A",
                        aa1: "N/A",
                        aa2: "",
                        aa3: "",
                        aa4: "",
                        aa5: "",
                        aa6: "",
                        link: "yes",
                    },

    ]




    


  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Publications |  Editorial Board" /> 
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                <h2 class="text-center">Editorial Board</h2>
                <br />

                    

                    <br/> 
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="board_memebership_list">                                    
                                        <div className='row'>
                                              
                                            {/* facultyListNew?.map((fac, index) => (
                                                <div key={index} className="col-md-4">
                                                    <div className="one_memebshop"> 
                                                        <div className="one_memebshop_image">
                                                            <img  src={fac.img ? fac.img : '/assets/img/dummy_img_user.jpg'}   alt={fac.name}   className="img-fluid" />
                                                        </div> 
                                                        <div className="one_memebshop_content">
                                                            <h4>{fac.name}</h4>
                                                            <p>{fac.position}</p>
                                                            <p>{fac.email}</p>
                                                            <Link to={fac.link}>
                                                                View Details
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) */}
                                            
                                            
                                            {facultyList?.map((fac2, index) => (
                                                <div key={index} className="col-md-4">
                                                    <div className="one_memebshop"> 
                                                        <div className="one_memebshop_image">
                                                            <img  src={fac2.img ? fac2.img : '/assets/img/dummy_img_user.jpg'}   alt={fac2.name}   className="img-fluid" />
                                                        </div> 
                                                        <div className="one_memebshop_content">
                                                            <h4>{fac2.name}</h4>
                                                            <p>{fac2.position}</p>
                                                            <p>{fac2.email}</p>
                                                             <br/>
                                                             {fac2.link !== "no" && (
                                                                <Button 
                                                                    variant="contained" 
                                                                    onClick={() => handleDetail(fac2)}
                                                                >
                                                                    View Detail
                                                                </Button>
                                                            )}

                        
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}



                                            
                                        </div>
                                </div>
 

                            </div>
                        </div>
                    </div>

                    <br/> 



 
 


                </div>
            </section>

    </>
  )
}

export default EditorialBoard