import React, { useState } from "react";
import { Stack, Grid, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const MenuWidgets = ({ headerMenus, color }) => {
  const menuStyle = {
    display: "flex",
    justifyContent: "center", // Align menus at the center horizontally
    alignItems: "center", // Align menus at the center vertically
  };

  const btnStyle = {
    padding: "10px 1px",
    background: color ? color : "#F8F8F8",
    minWidth: "100px",
    color: color ? "#fff" : "#000",
    border: "1px outset gray",
    display: "inline-block", // To align the submenu horizontally
    position: "relative", // To position the submenu absolutely
    textAlign:'center'
  };

  const submenuStyle = {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#F8F8F8",
    minWidth: "100%",
    zIndex: 1,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow for better visibility
  };

  const submenuItemStyle = {
    padding: "10px",
    whiteSpace: "nowrap", // Prevent text from wrapping
  };

  const dividerStyle = {
    margin: "4px 0",
    borderBottom: "1px solid #000",
  };

  const submenuStyle1 = {
    position: "absolute",
    top: "auto",
    right: "100%",
    backgroundColor: "#F8F8F8",
    // minWidth: "100%",
    // zIndex: 1,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow for better visibility
    marginTop:-26
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredSubIndex, setHoveredSubIndex] = useState(null);
  const [hoveredSubSubIndex, setHoveredSubSubIndex] = useState(null);

  const handleMenuItemClick = () => {
    setHoveredIndex(null); // Close submenu on menu item click
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div style={menuStyle}>
          <Stack direction="row">
            <ul className="headerul" style={{ padding: 0, margin: 0, listStyleType: "none" }}>
              {headerMenus.map((item, index) => (
                <li
                  key={index}
                  style={btnStyle}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <Link
                    to={item.path}
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={handleMenuItemClick}
                  >
                    {item.mainHeader}
                  </Link>
                  {item.subHeader && hoveredIndex === index && (
                    <ul
                      style={{
                        ...submenuStyle,
                        left: window.innerWidth - (btnStyle.minWidth + index * 30) < 0 ? "auto" : "0", // Open submenu to the left if not enough space
                        right: window.innerWidth - (btnStyle.minWidth + index * 30) < 0 ? "0" : "auto",
                      }}
                    >
                      {item.subHeader.map((subItem, subIndex) => (
                        <React.Fragment key={subIndex}>
                          {subIndex > 0 && <li style={dividerStyle}></li>}
                          <li
                            onMouseEnter={() => setHoveredSubIndex(subIndex)}
                            onMouseLeave={() => setHoveredSubIndex(null)}
                            style={{ width: "100%",color:'#000',textAlign:'left' }} // Set submenu item width to 100%
                          >
                            <Link
                              to={subItem.path}
                              style={{
                                ...submenuItemStyle,
                                color:
                                  hoveredSubIndex === subIndex
                                    ? "#d32f2f"
                                    : "inherit",
                              }}
                              onClick={handleMenuItemClick}
                            >
                              {subItem.title}
                            </Link>
                            {subItem.lastHeader && hoveredSubIndex === subIndex && (
                              <React.Fragment>
                                <IconButton
                                  style={{ position: "absolute", right: "-24px", top: "50%", transform: "translateY(-50%)" }}
                                  size="small"
                                >
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                                <ul style={submenuStyle1}>
                                  {subItem.lastHeader.map((lastItem, lastIndex) => (
                                    <React.Fragment key={lastIndex}>
                                      {lastIndex > 0 && <li style={dividerStyle}></li>}
                                      <li
                                        onMouseEnter={() => setHoveredSubSubIndex(lastIndex)}
                                        onMouseLeave={() => setHoveredSubSubIndex(null)}
                                        style={{ width: "100%",color:'#000',textAlign:'left' }}
                                      >
                                        <Link
                                          to={lastItem.path}
                                          style={{
                                            ...submenuItemStyle,
                                            color:
                                              hoveredSubSubIndex === lastIndex
                                                ? "#d32f2f"
                                                : "inherit",
                                          }}
                                          onClick={handleMenuItemClick}
                                        >
                                          {lastItem.title}
                                        </Link>
                                      </li>
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </React.Fragment>
                            )}
                          </li>
                        </React.Fragment>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </Stack>
        </div>
      </Grid>
    </Grid>
  );
};

export default MenuWidgets;
