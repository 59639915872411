import React, { useEffect, useRef } from 'react';
import { Grid, Typography, Link } from "@mui/material";

const Notifications=()=>{

    const tickerRef = useRef(null);


    const notificationList=[
        /*{title:'Agriculture  Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/Agriculture  Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'B.Sc.Biotech. Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/B.Sc.Biotech. Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'B.Sc.Med.M.Bio Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/B.Sc.Med.M.Bio Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'B.Sc.MLT Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/B.Sc.MLT Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'B.Tech. CSE Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/B.Tech. CSE Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'BA(POL.)Sc. Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/BA(POL.)Sc. Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'BALL.B. Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/BALL.B. Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'BBALL.B. Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/BBALL.B. Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'BCA Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/BCA Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'DMLT Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/DMLT Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'LL.B.& LLM Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/LL.B.& LLM Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'M.Sc.M.Bio Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/M.Sc.M.Bio Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'Pharmacy Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/Pharmacy Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'SBMC Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/SBMC Theory Date Sheet End Term Examinations June - 2024.pdf'},*/

       /*{title:'AHS Date Sheet Mid Term Examinations September-2024 ',url:'/assets/img/new_images/examination_datesheet/AHS Date Sheet Mid Term Examinations September-2024.pdf'},

        {title:'B.Sc.(H) Agri Date Sheet Mid Term Examinations September-2024 ',url:'/assets/img/new_images/examination_datesheet/B.Sc.(H) Agri Date Sheet Mid Term Examinations September-2024.pdf'},

        {title:'B.Tech. CSE Date Sheet Mid Term Examinations September-2024 ',url:'/assets/img/new_images/examination_datesheet/B.Tech. CSE Date Sheet Mid Term Examinations September-2024.pdf'},

        {title:'BA(POL.)Sc. Date Sheet Mid Term Examinations September-2024 ',url:'/assets/img/new_images/examination_datesheet/BA(POL.)Sc. Date Sheet Mid Term Examinations September-2024.pdf'},

        {title:'BALL.B. Date Sheet Mid Term Examinations September-2024 ',url:'/assets/img/new_images/examination_datesheet/BALL.B. Date Sheet Mid Term Examinations September-2024.pdf'},

        {title:'BBALL.B. Date Sheet Mid Term Examinations September-2024 ',url:'/assets/img/new_images/examination_datesheet/BBALL.B. Date Sheet Mid Term Examinations September-2024.pdf'},



        {title:'BCA Date Sheet Mid Term Examinations September-2024 ',url:'/assets/img/new_images/examination_datesheet/BCA Date Sheet Mid Term Examinations September-2024.pdf'},


        {title:'LL.B. Date Sheet Mid Term Examinations September-2024 ',url:'/assets/img/new_images/examination_datesheet/LL.B. Date Sheet Mid Term Examinations September-2024.pdf'},

        {title:'Pharmacy Date Sheet Sessional Examinations -I September-2024 ',url:'/assets/img/new_images/examination_datesheet/Pharmacy Date Sheet Sessional Examinations -I September-2024.pdf'},

        {title:'SBMC Date Sheet Mid Term Examinations September-2024 ',url:'/assets/img/new_images/examination_datesheet/SBMC Date Sheet Mid Term Examinations September-2024.pdf'},*/

      /*  {title:'Notice for Submission of Regular Exam. form   - 25th Sept 2024',url:'/assets/img/new_images/examination_datesheet/Notice for Submission of Regular Exam. form.pdf'},
        {title:'Notice for Submission of Re-appear Exam. form   - 25th Sept 2024 ',url:'/assets/img/new_images/examination_datesheet/Notice for Submission of Re-appear Exam. form.pdf'},
      */

        /*
        {title:'Agriculture Theory 1st Sem. Datesheet ETE December-2024',url:'/assets/img/new_images/examination_datesheet/Agriculture Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'AHS Dept. Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/AHS Dept. Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'BA(Pol.)Sc. Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/BA(Pol.)Sc. Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'Engg. & CA Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/Engg. & CA Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'Law Deptt. Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/Law Deptt. Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'Pharmacy Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/Pharmacy Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:' SBMC Deptt. Theory 1st Sem. Datesheet ETE December-2024 ',url:'/assets/img/new_images/examination_datesheet/SBMC Deptt. Theory 1st Sem. Datesheet ETE December-2024.pdf'},*/

        {title:'Agriculture Theory 1st Sem. Datesheet ETE December-2024',url:'/assets/img/new_images/examination_datesheet/Agriculture Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'AHS Dept. Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/AHS Dept. Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'BA(Pol.)Sc. Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/BA(Pol.)Sc. Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'Engg. & CA Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/Engg. & CA Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'Law Deptt. Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/Law Deptt. Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'Pharmacy Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/Pharmacy Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:' SBMC Deptt. Theory 1st Sem. Datesheet ETE December-2024 ',url:'/assets/img/new_images/examination_datesheet/SBMC Deptt. Theory 1st Sem. Datesheet ETE December-2024.pdf'},

        
        
    ]
 

    useEffect(() => {
        const ticker = tickerRef.current;
        const clone = ticker.cloneNode(true);
        ticker.parentNode.appendChild(clone);

        return () => {
        clone && clone.remove();
        };
    }, []);

    return(
        /*
        <Grid container spacing={2}>
            {notificationList.map((notiy)=>(
                <Grid item xs={12}>
            <span style={{ marginRight: '8px' }}>•</span><Link href={notiy.url} target='_blank'>{notiy.title}</Link>
                 
            </Grid>
            ))}
            
        </Grid> **/

        <div className="news-ticker-vertical">
          <div className="ticker-wrap-vertical">
            <div className="ticker-vertical" ref={tickerRef}>
              {notificationList.map((item, index) => (
                <a key={index} href={item.url} className="ticker-item-vertical" target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              ))}
            </div>
          </div>
        </div>  
    )
}
export default Notifications;